export const analyticsEvents = {
  dialog: {
    other_costs: 'Clique no dialog - Outros custos',
    economy: 'Clique no dialog - Economia',
    environment_impact: 'Clique no dialog - Impacto Ambiental',
  },
  help: {
    open: (title: string) => `Clique no botão de ajuda - ${title}`,
    open_topic: (topic: string) => `Clique no Tópico - ${topic}`,
    open_faq: (question: string) =>
      `Clique em Principais Dúvidas - ${question}`,
    back_to_app: 'Clique no botão - Voltar para o aplicativo',
    talk_to_us_default:
      'Fale com a gente - abriu whatsapp (sem mensagem padrão)',
    talk_to_us_lemon_not_emitted:
      'Fale com a gente - abriu whatsapp (ajuda conta não emitida)',
    talk_to_us_invoice_help: 'Fale com a gente - abriu whatsapp (ajuda conta)',
    talk_to_us_no_document_help: 'Fale com a gente - abriu whatsapp (sem CPF)',
    talk_to_user_no_contact_access:
      'Fale com a gente - abriu whatsapp (sem contato)',
    navigate_to_help: 'Visualização - Navegou para ajuda',
    found_what_was_looking_for: 'Clique no botão - Encontrou o que procurava',
    still_need_help: 'Clique no botão - Ainda preciso de ajuda',
    rate_app: 'Clique no botão - Avaliar',
    rate_app_not_now: 'Clique no botão - Agora não',
    view_all_questions: 'Clique no botão - Ver todas',
    chat_via_whatsapp: 'Clique no botão em Fale com a gente - Whatsapp',
  },
  bill: {
    open: (month: string) => `Clique na conta - Abriu a conta de ${month}`,
    open_bill_section: 'Clique na ação - Abriu a seção de contas em aberto',
    copy: 'Clique na ação - Copiou o código',
    copy_failed: 'Clique na ação - Falha ao copiar o código',
    open_bank_slip: 'Clique na ação - Abriu URL do boleto',
    open_bank_slip_dialog: 'Clique na ação - Abriu dialog do boleto',
    close_bank_slip_dialog: 'Clique na ação - Fechou dialog do boleto',
    open_pix_dialog: 'Clique na ação - Abriu dialog do Pix',
    close_pix_dialog: 'Clique na ação - Fechou dialog do Pix',
    copy_pix_code: 'Clique na ação - Copiou o código Pix',
    copy_bank_slip: 'Clique na ação - Copiou código do boleto',
    view: 'Visualizou a conta',
  },
  shortcut: {
    copy_pix_code: 'Clique no atalho - Copiar código Pix',
    view_pix_code: 'Clique no atalho - Visualizar código Pix',
    copy_bank_slip: 'Clique no atalho - Copiar código do boleto',
    view_bank_slip: 'Clique no atalho - Visualizar boleto',
    notify_payment: 'Clique no atalho - Notificar pagamento',
    help: 'Clique no atalho - Ajuda',
    dialog_notify_payment: 'Clique no atalho - Notificar pagamento (dialog)',
    dialog_help: 'Clique no atalho - Ajuda (dialog)',
  },
  aquisition: {
    open_aquisition_dialog:
      'Seu negócio ainda não usa Lemon? - Abriu dialog para confirmar navegação para aquisição',
    go_to_aquisition: 'Seu negócio ainda não usa Lemon? - Ir para aquisição',
    close_dialog_without_navigate:
      'Seu negócio ainda não usa Lemon? - Fechou dialog de confirmação sem navegar',
  },
  appBar: {
    place_data_shortcut: 'Abriu dialog na AppBar - Editar dados do lugar',
    place_select_shortcut: 'Abriu dialog na AppBar - Selecionar lugar',
  },
  edit_place: {
    nickname_input_start: 'Editar Place - Foco no campo alterar nickname',
    nickname_input_submit: 'Editar Place - Usuário digitou o nickname',
  },
  edit_person: {
    nickname_input_start: 'Editar Person - Foco no campo alterar nickname',
    nickname_input_submit: 'Editar Person - Usuário digitou o nickname',
  },

  notifications: {
    activate: 'Notificações - Ativou',
    activate_failed: 'Notificações - Falha ao ativar',
    skip: 'Notificações - Fechou sem ativar',
    try_again: 'Notificações - Tentou novamente',
    submit_push_token: 'Notificações - Enviou o token de push para o servidor',
    denied: (type: string) => `Notificações - Permissão negada - ${type}`,
    notSupported: (type: string) => `Notificações - Não suportado - ${type}`,
    received_foreground: 'Notificação - Recebida em primeiro plano',
    received_background: 'Notificação - Recebida em segundo plano',
    clicked: 'Notificação - Clicou na notificação',
  },
  place_first_access: {
    nickname_input_start:
      'Primeiro acesso de lugar - focus campo alterar nickname',
    nickname_input_submit:
      'Primeiro acesso de lugar - usuário digitou o nickname',
    select_icon: 'Primeiro acesso de lugar - selecionou ícone',
  },
  person_first_access: {
    nickname_input_start:
      'Primeiro acesso de pessoa - focus campo alterar nickname',
    nickname_input_submit:
      'Primeiro acesso de pessoa - usuário digitou o nickname',
    change_contacts: 'Primeiro acesso de pessoa - alterou contatos de acesso',
    open_change_contacts: (type: string) =>
      `Primeiro acesso de pessoa - Abriu dialog para alterar ${type}`,
  },
  typeform: {
    open: (id: string) => `Typeform - Abriu typeform ${id}`,
  },
  profile_actions: {
    open_logout_dialog: 'Ação em Perfil - Abriu dialog de logout',
    logout: 'Ação em Perfil - Clicou em logout',
    navigate_to_profile: 'Visualização - Navegou para perfil',
  },
  login: {
    cpf_input_start: 'Login - Foco no campo CPF',
    cpf_input_submit: 'Login - Usuário digitou o CPF completo',
    cpf_next: 'Login - Clicou em continuar',
    valid_cpf: 'Login - CPF válido',
    invalid_cpf: 'Login - CPF inválido',
    lugares: 'Login - Usuário acessou lugares após login',

    select_method: (method: string) =>
      `Login - Clicou em selecionar método ${method}`,

    validation_code_input_start: 'Login - Foco no campo código de validação',
    validation_code_input_submit:
      'Login - Usuário digitou o código de validação',
    valid_validation_code: 'Login - Código de validação válido',
    invalid_validation_code: 'Login - Código de validação inválido',
    validation_code_next: 'Login - Clicou em continuar',

    resend_validation_code: 'Login - Clicou em reenviar código de validação',
    select_another_method: 'Login - Clicou em selecionar outro método',
  },
  new_interface: {
    open_disabled_payment_dialog:
      'Nova Conta - Clique no botão - Pagamento indisponível',
    close_payment_dialog: 'Nova Conta - Fechou o dialog de pagamento',
    copy_payment_bankslip_code:
      'Nova Conta - Clique no botão - Copiar código de barras',
    open_payment_bankslip: 'Nova Conta - Clique no botão - Abrir boleto',
    notify_new_invoice: 'Nova Conta - Tela de noticia de nova interface',
    go_to_lemon_and_disco_invoice: (discoName: string) =>
      `Nova Conta - Clique no botão - Ir para conta Lemon+${discoName}`,
    info: 'Nova Conta - Clique no botão - Info',
    view_details:
      'Nova Conta - Clique no botão - Incluso nessa conta - Ver detalhes',
    view_not_included_details:
      'Nova Conta - Clique no botão - Não incluso - Ver detalhes',
    view_all_questions:
      'Nova Conta - Clique no botão - Principais dúvidas - Ver todas',
    open_faq: (question: string) =>
      `Nova Conta - Clique no botão - Principais dúvidas - ${question}}`,
    go_to_payment: 'Nova Conta - Clique no botão - Ir para o pagamento',
    no_lemon_bill_emitted_info:
      'Nova Conta - Clique no botão - Sem cobrança da Lemon - Saiba mais',
    open_cancelled_dialog:
      'Nova Conta - Clique no botão - Boleto não disponível',
    open_bankslip: 'Nova Conta - Clique no botão - Abrir boleto',
    call_to_app_banner:
      'Nova Conta - Clique no botão - Baixar aplicativo (banner)',
    call_to_app: 'Nova Conta - Clique no botão - Baixar aplicativo',
    copy_pix_code: 'Nova Conta - Clique no botão - Copiar código Pix',
    view_pix_feature_discovery: 'Nova Conta - Tela de noticia de Pix',
    close_pix_feature_discovery:
      'Nova Conta - Clique no botão - Fechar noticia de Pix',
    open_new_payment: (paymentMethod: string) =>
      `Nova Conta - Clique no botão - Ir para o pagamento via ${paymentMethod}`,
  },
  csat: {
    open: 'CSAT - Abriu a pesquisa',
    close: 'CSAT - Fechou a pesquisa',
    submit: 'CSAT - Clicou em enviar resposta da pesquisa',
  },
}
